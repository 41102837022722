<template>
  <div>Logging-out&hellip;</div>
</template>

<script>
export default {
  mounted () {
    this.$store.commit('logout')
    this.$router.push({ name: 'Home' })
  }
}
</script>